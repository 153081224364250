import { createNearInstance, NEAR_CONFIG } from './create-near-instance';
import { Near, WalletConnection, providers } from 'near-api-js';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import type { JsonRpcProvider } from 'near-api-js/lib/providers';

type AppServices = {
  near: Near;
  walletConnection: WalletConnection;
  provider: JsonRpcProvider;
};

const AppContext = React.createContext<AppServices | null>(null);

export function NearContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [context, setContext] = useState<AppServices | null>(null);

  useEffect(() => {
    const init = async () => {
      const near = await createNearInstance();
      const walletConnection = new WalletConnection(near, 'roketo');

      const provider = new providers.JsonRpcProvider(
        `https://rpc.${NEAR_CONFIG.networkId}.near.org`,
      );

      setContext({
        near,
        provider,
        walletConnection,
      });
    };
    init();
  }, []);

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}

export function useNearContext() {
  const context = useContext(AppContext);
  return context;
}
