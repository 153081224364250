import { keyStores, connect } from 'near-api-js';
export const NEAR_CONFIG = {
  accountSuffix: 'testnet',
  networkId: 'testnet',
  nodeUrl: 'https://rpc.testnet.near.org',
  contractName: 'dev-1635510732093-17387698050424',
  walletUrl: 'https://wallet.testnet.near.org',
};

export async function createNearInstance() {
  const keyStore = new keyStores.BrowserLocalStorageKeyStore();
  const near = await connect({
    nodeUrl: NEAR_CONFIG.nodeUrl,
    walletUrl: NEAR_CONFIG.walletUrl,
    networkId: NEAR_CONFIG.networkId,
    keyStore,
    headers: {},
  });

  return near;
}
