import { NearContextProvider } from "lib/near/near-context";
import { useToastQuery } from "lib/toast";
import React, { Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

const FailurePage = React.lazy(() => import("./pages/failure-page"));
const NotFoundPage = React.lazy(() => import("./pages/not-found-page"));
const PaymentPage = React.lazy(() => import("./pages/payment-page"));
const PendingPage = React.lazy(() => import("./pages/pending-page"));
const SendPage = React.lazy(() => import("./pages/send-page"));
const SuccessPage = React.lazy(() => import("./pages/success-page"));

function AppContainer() {
  useToastQuery();

  return (
    <div>
      <Outlet />
    </div>
  );
}
function App() {
  return (
    <NearContextProvider>
      <BrowserRouter>
        <Suspense fallback={<>...</>}>
          <Routes>
            <Route path="/" element={<AppContainer />}>
              <Route path="/payment/:id" element={<PaymentPage />} />
              <Route path="/payment/:id/success" element={<SuccessPage />} />
              <Route path="/payment/:id/failure" element={<FailurePage />} />
              <Route path="/payment/:id/send" element={<SendPage />} />
              <Route path="/payment/:id/pending" element={<PendingPage />} />
              <Route path="/not-found" element={<NotFoundPage />} />
              <Route path="*" element={<Navigate to="/not-found" />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </NearContextProvider>
  );
}

export default App;
