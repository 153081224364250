import "./toast.scss";
import simpleToast, { ToastOptions } from "react-simple-toasts";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const toast = (message: string, options?: ToastOptions) => {
  console.log("Toast is called", message);
  simpleToast(message, { className: "pay-with-near-toast", ...options });
};

export function useToastQuery() {
  const [searchParams, setSearchParams] = useSearchParams();

  const toastMessage = searchParams.get("toast");

  useEffect(() => {
    setTimeout(() => {
      if (toastMessage) {
        toast(decodeURIComponent(toastMessage), {
          time: Math.max(toast.length * 150, 3500),
        });
        searchParams.delete("toast");
        setSearchParams(searchParams);
      }
    }, 100);
  }, [toastMessage, searchParams, setSearchParams]);
}
